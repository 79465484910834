@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: 'Montserrat', sans-serif;
  font-style: normal; */
}

.lime {
  color:#a2d109;
}

.hot-pink {
  color: #d30070;
}

.dark-olive {
  color: #6A8426;
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Remove scroll bars */

::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}

::-webkit-scrollbar-track {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.5);
  box-shadow: inset 0 0 2px rgba(0,0,0,0.5);
}

::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.5); 
  box-shadow: inset 0 0 2px rgba(0,0,0,0.5); 
}

.touch-scroll {
  -webkit-overflow-scrolling: touch;
}

/* Swicth 1 */

.switch-1 {
  position: relative;
  display: inline-block;
  width: 7.5rem;
  height: 2.5rem;
  margin: 0;
  padding: 0;
  font-weight: normal !important;
  opacity: 0.7;
}

.switch-1 input { 
  opacity: 0;
  width: 0;
  height: 0;
}

/* Switch 2 */

.switch-2 {
  position: relative;
  display: inline-block;
  width: 5rem;
  height: 2rem;
  margin: 0;
  padding: 0;
  font-weight: normal !important;
}

.switch-2 input { 
  opacity: 0;
  width: 0;
  height: 0;
}

/* Swicth 3 */

.switch-3 {
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin: 0;
  padding: 0;
  font-weight: normal !important;
}

.switch-3 input { 
  opacity: 0;
  width: 0;
  height: 0;
}

/* Slider base 1 */

.slider-base-1 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  -webkit-transition: .4s;
  transition: .4s;
  text-align: center;
}

input:checked + .slider-base-1 {
  background-color: #000;
}

input:focus + .slider-base-1 {
  box-shadow: 0 0 1px #000;
}

.slider-base-1.round {
  border-radius: 34px;
}

.slider-base-1.round:before {
  border-radius: 50%;
}

/* Slider base 2 */

.slider-base-2 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #6A8426;
  -webkit-transition: .4s;
  transition: .4s;
  text-align: center;
}

input:checked + .slider-base-2 {
  background-color: #d30070;
}

input:focus + .slider-base-2 {
  box-shadow: 0 0 1px #6A8426;
}

.slider-base-2.round {
  border-radius: 34px;
}

.slider-base-2.round:before {
  border-radius: 50%;
}

/* Slider 4 */

.slider-4:before {
  position: absolute;
  content: "123";
  height: 2.5rem;
  width: 2.5rem;
  line-height: 2.5rem;
  left: 0px;
  bottom: 0px;
  background-color: #fff;
  color: #000;
  background-position: center; 
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider-4:before {
  content: "ABC";
  -webkit-transform: translateX(80px);
  -ms-transform: translateX(80px);
  transform: translateX(80px);
  background-color: #fff;
  color: #000;
}

/* Slider 5 */

.slider-5:before {
  position: absolute;
  content: "";
  height: 2rem;
  width: 2rem;
  left: 0px;
  bottom: 0px;
  background-color: #FFF;
  background-image: url("./components/assets/list-icon.svg");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: center; 
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider-5:before {
  -webkit-transform: translateX(48px);
  -ms-transform: translateX(48px);
  transform: translateX(48px);
  background-color: #FFF;
  background-image: url("./components/assets/grid-icon.svg");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: center;
}

.slider-5:after {
  content:'GRID';
  color: white;
  display: block;
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 65%;
  font-size: 0.75rem;
}

input:checked + .slider-5:after {  
  content:'LIST';
  left: 35%;
}

/* Slider 6 */

.slider-6:before {
  position: absolute;
  content: "";
  height: 2rem;
  width: 2rem;
  left: 0px;
  bottom: 0px;
  background-image: url("./components/assets/unchecked.svg");
  background-repeat: no-repeat;
  background-size: 2rem 2rem;
  background-position: center;
}

input:checked + .slider-6:before {
  background-image: url("./components/assets/checked.svg");
  background-repeat: no-repeat;
  background-size: 2rem 2rem;
  background-position: center;
}

.important {
  display: none !important;
}

.sidebar-btn {
  writing-mode: vertical-lr;
  text-orientation: sideways-right;
  transform: rotate(-180deg);
}

.bars-fa {
  width: 2rem;
  height: 2rem;
}

.bars-large-fa {
  width: 3rem;
  height: 3rem;
}

.user-fa {
  width: 2rem;
  height: 2rem;
}

.arrow-left-fa {
  width: 2rem;
  height: 2rem; 
}

.times-large-fa {
  width: 3rem;
  height: 3rem;
}

.home-icon {
  width: 1rem;
  height: 1rem;
}

.modal-one-back-button-fa {
  width: 2.5rem;
  height: 2.5rem;
}

.modal-one-bars-large-fa {
  width: 2rem;
  height: 2rem;
}

.modal-one-times-large-fa {
  width: 2.5rem;
  height: 2.5rem;
}

.product-menu-table-container {
  max-height: 90%;
}

.product-menu-table thead th:first-child { 
  min-width: 9rem;
  z-index: 1000;
}

.product-menu-table td:first-child {
  /* border: 1px solid #FFF; */
  min-width: 9rem;
  height: 8rem;
}

.product-menu-table thead th { 
  min-width: 10rem;
  position: sticky; 
  top: 0; 
}

.product-menu-table td {
  /* border: 1px solid #FFF; */
  min-width: 12rem;
  height: 8rem;
}

.fix {
  position: sticky;
  left: 0;
  top: auto;
  min-width: 9rem;
  z-index: 999;
}

.product-menu-table-container::-webkit-scrollbar {
  height: 15px;
  width: 15px;
}

.product-menu-table-container::-webkit-scrollbar-track {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 4px rgba(100, 116, 139, 1);
  box-shadow: inset 0 0 4px rgba(100, 116, 139, 1);
  background-color: rgba(37, 37, 37, 0.5); 
}

.product-menu-table-container::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 4px rgba(100, 116, 139, 1); 
  box-shadow: inset 0 0 4px rgba(100, 116, 139, 1); 
  background-color: rgba(37, 37, 37, 0.5); 
}

/* 3 Position Radio Toggle */

.toggle_radio {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 2rem;
  background-color: transparent;
}

.toggle_radio input[type=radio] {
  display: none;
}

.toggle_radio label {
  display: inline-block;
  position: relative;
  width: 33.33%;
  height: 2rem;
  line-height: 2rem;
  border-radius: 25%;
  color: #FFF;
  cursor: pointer;
  text-align: center;
  background: transparent;
  z-index: 3;
}

.toggle_option_slider {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: background .35s ease;
}

.toggle_option_slider::after {
  position: absolute;
  content: '';
  width: 33.33%;
  height: 2rem;
  border-radius: 2rem;
  background: #a2d109;
  z-index: 2;
  transition: left .35s ease;
}

#scanned-items-filter_all:checked ~ .toggle_option_slider::after {
  left: 0px;
}

#scanned-items-filter_order:checked ~ .toggle_option_slider::after {
  left: calc((100% - 33.33%)/2);
}

#scanned-items-filter_collect:checked ~ .toggle_option_slider::after {
  left: calc((100% - 33.33%));
}

.edit-product-times-large-fa,
.trash-small-fa,
.toolbox-small-fa {
  width: 1.25rem;
  height: 1.25rem;
}

.collapsible-three {
  position: absolute;
  left: 56%;
  width: 15rem;
  height: 100%;
}

/* New CSS */

/* Breakpoint prefix	Minimum width	CSS
sm	640px	@media (min-width: 640px) { ... }
md	768px	@media (min-width: 768px) { ... }
lg	1024px	@media (min-width: 1024px) { ... }
xl	1280px	@media (min-width: 1280px) { ... }
2xl	1536px	@media (min-width: 1536px) { ... } 

PC

1024px - 931px 
1366px - 1242px 
1092px - 1745px

Samsung Tablet 
1334px          */


/* 1xl	1366px	@media (min-width: 1366px) { ... } */

@media (min-width: 1366px) { 

  .home-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .modal-one-bars-large-fa {
    width: 2.5rem;
    height: 2.5rem;
  }

  .edit-product-times-large-fa {
    width: 1.5rem;
    height: 1.5rem;
  }

  .collapsible-three {
    left: 56.2%;
    width: 16rem;
  }

}

/* 2xl	1536px	@media (min-width: 1536px) { ... } */

@media (min-width: 1536px) {

  .switch-1 {
      width: 10.5rem;
      height: 3.5rem;
  }

  .switch-2 {
    width: 8rem;
    height: 3rem;
  }

  .switch-3 {
    width: 3rem;
    height: 3rem;
  }

  /* Slider 4 */

  .slider-4:before,
  .slider-4:after {
      width: 3.5rem;
      height: 3.5rem;
      line-height: 3.5rem;
  }

  input:checked + .slider-4:before {
      -webkit-transform: translateX(112px);
      -ms-transform: translateX(112px);
      transform: translateX(112px);
  }

  /* Slider 5 */

  .slider-5:before {
    background-size: 30px 30px;
  }
  
  input:checked + .slider-5:before {
    background-size: 30px 30px;
  }

  .slider-5:before {
    height: 3rem;
    width: 3rem;  
    line-height: 3rem;
  }
  
  input:checked + .slider-5:before {
    -webkit-transform: translateX(80px);
    -ms-transform: translateX(80px);
    transform: translateX(80px);
  }

  .slider-5:after {
    left: 65%;
    font-size: 1.25rem;
  }

  /* Slider 6 */

  .slider-6:before {
    height: 3rem;
    width: 3rem;
    background-size: 3rem 3rem;
  }
  
  input:checked + .slider-6:before {
    background-size: 3rem 3rem;
  }

  .bars-fa {
    width: 3rem;
    height: 3rem;
  }

  .bars-large-fa {
    width: 4rem;
    height: 4rem;
  }
  
  .user-fa {
    width: 3rem;
    height: 3rem;
  }

  .arrow-left-fa {
    width: 3rem;
    height: 3rem;
  }

  .times-large-fa {
    width: 4rem;
    height: 4rem;
  }

  .home-icon {
    width: 2rem;
    height: 2rem;
  }

  .modal-one-back-button-fa {
    width: 4rem;
    height: 4rem;
  }

  .modal-one-bars-large-fa {
    width: 3.5rem;
    height: 3.5rem;
  }

  .modal-one-times-large-fa {
    width: 4rem;
    height: 4rem;
  }

  .product-menu-table thead th:first-child { 
    min-width: 13rem;
  }

  .product-menu-table td:first-child {
    min-width: 13rem;
    height: 11rem;
  }

  .product-menu-table thead th { 
    min-width: 16rem;
  }

  .product-menu-table td {
    min-width: 16rem;
    height: 11rem;
  }

  /* 3 Position Radio Toggle */

  .toggle_radio {
    height: 3rem;
  }

  .toggle_radio label {
    height: 3rem;
    line-height: 3rem;
  }

  .toggle_option_slider::after {
    height: 3rem;
  }

  .edit-product-times-large-fa,
  .trash-small-fa,
  .toolbox-small-fa {
    width: 2rem;
    height: 2rem;
  }

  .collapsible-three {
    left: 56.3%;
    width: 24rem;
  }

}
